<template>
    <b-overlay :show="loading">
        <b-row class="mb-2">
            <!-- Header -->
            <b-col cols="12" class="mb-2">
                <b-button variant="primary" v-if="allowCreate() && !hasPermissionApprove" @click.prevent="add">Tambah</b-button>
            </b-col>
            <b-col sm="12" md="6">
                <b-form-group class="mb-0">
                    <label class="d-inline-block text-sm-left mr-50">Per page</label>
                    <b-form-select
                        id="perPageSelect"
                        v-model="perPage"
                        size="sm"
                        :options="pageOptions"
                        class="w-50"
                    />
                </b-form-group>
            </b-col>
            <b-col sm="12" md="6">
                <b-form-group
                label="Filter"
                label-cols-sm="3"
                label-align-sm="right"
                label-size="sm"
                label-for="filterInput"
                class="mb-0"
                >
                    <b-input-group size="sm">
                        <b-form-input
                        id="filterInput"
                        v-model="filter"
                        type="search"
                        placeholder="Type to Search"
                        />
                        <b-input-group-append>
                        <b-button :disabled="!filter" @click="filter = ''"> Clear </b-button>
                        </b-input-group-append>
                    </b-input-group>
                </b-form-group>
            </b-col>
            <!-- / -->

        </b-row>
        <!-- Body -->
        <b-card>
            <b-row>
                <b-col cols="12">
                    <b-table :filter="filter" :per-page="perPage" :current-page="currentPage" responsive :fields="fields" :items="returs">
                        <template #cell(order)="{index}">
                            {{++index}}
                        </template>
                        <template #cell(jenis)="{item}">
                            {{item.jenis == 1 ? 'Rokok' : 'Non-Rokok'}}
                        </template>
                        <template #cell(tanggal)="{item}">
                            {{ humanDate(item.tanggal) }}
                        </template>
                        <template #cell(supplier)="{item}">
                            <b-badge v-if="item.supplier_id" variant="light-success">
                                Sudah dipilih
                            </b-badge>
                            <i v-else class="text-danger">Supplier belum dipilih</i>
                        </template>
                        <template #cell(status)="{item}">
                            <b-badge variant="light-primary">
                                {{item.status}}
                            </b-badge>
                        </template>
                        <template #cell(actions)="{item}">
                            <div class="flex align-items-center justify-center">
                                <b-button
                                    size="sm"
                                    @click="detail(item)"
                                    class="mr-1"
                                    variant="outline-info"
                                    >
                                    <feather-icon icon="EyeIcon" />
                                </b-button>
                                <b-button
                                    v-if="allowUpdate() && item.lapor == 0 && !hasPermissionApprove"
                                    size="sm"
                                    @click="edit(item)"
                                    class="mr-1"
                                    variant="outline-info"
                                    >
                                    <feather-icon icon="EditIcon" />
                                </b-button>
                                <b-button
                                    v-if="allowDelete() && item.lapor == 0 && !hasPermissionApprove"
                                    size="sm"
                                    @click="remove(item)"
                                    class="mr-1"
                                    variant="outline-danger"
                                    >
                                    <feather-icon icon="TrashIcon" />
                                </b-button>
                            </div>
                        </template>
                    </b-table>
                </b-col>
                <b-col cols="12">
                    <b-pagination
                        v-model="currentPage"
                        :total-rows="totalRows"
                        :per-page="perPage"
                        align="center"
                        size="sm"
                        class="my-0"
                    />
                </b-col>
            </b-row>
        </b-card>
        <!-- / -->
    </b-overlay>
</template>
<script>
import {BOverlay, BRow, BBadge, BCol, BCard, BPagination, BTable, BFormGroup, BInputGroup, BInputGroupAppend, BButton, BFormInput, BFormSelect} from 'bootstrap-vue'
export default {
    components: {
        BOverlay,
        BRow, BCol, BBadge, BFormGroup, BCard, BPagination, BTable, BInputGroup, BInputGroupAppend, BButton, BFormInput, BFormSelect
    },
    computed: {

    },
    data: () => ({
        loading: false,
        perPage: 10,
        totalRows: 0,
        currentPage: 1,
        pageOptions: [10, 30, 50],
        filter: null,
        fields: [
        {key: 'order', label: 'No'},
          {key: 'tanggal', label: 'Tanggal'},
          {key: 'total', label: 'Total Retur'},
          {key: 'status', label: 'Pembayaran'},
          {key: 'actions', label: '#'}
        ],
        returs: [],
        jenis: null,
        menu: null,
        myDataOnly: false,
        hasPermissionApprove: false
    }),
    methods: {
        edit(item) {
            this.$router.push(`/retur-supplier/edit/${item.id}`)
        },
        remove(item)  {
            this.$swal({
                title: 'Anda yakin?',
                text: `Data pembelian ini akan dihapus`,
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Ya',
                cancelButtonText: 'Batal',
                customClass: {
                    confirmButton: 'btn btn-success',
                    cancelButton: 'btn btn-danger ml-1',
                }
            })
            .then(async res => {
                if(res.value) {

                    const payloadsRincian = item.rincian.map(rincian => {
                        return {
                            id: rincian.id,
                            fungsi: 2
                        }
                    })

                    const payload = {
                        fungsi: 1,
                        id: item.id
                    }

                    try {
                        this.loading = true
                        if(payloadsRincian.length > 0) {

                            await this.$store.dispatch('retur-supplier/saveRincian', payloadsRincian)
                            await this.$store.dispatch('retur-supplier/save', [payload])
                        }
                        else {
                            await this.$store.dispatch('retur-supplier/save', [payload])
                        }
                        this.loading = false
                        this.displaySuccess({
                            message: 'Data pembelian berhasil dihapus'
                        })
                        this.getReturSupplier()
                    }
                    catch(e) {
                        this.loading = false
                        this.displayError(e)
                        return false
                    }
                }
            })
        },
        add() {
            this.$router.push(`/retur-supplier/add`)
        },
        async getReturSupplier() {
            const params = {
                order: 'desc'
            }
            if(this.hasPermissionApprove) params.lapor = 1
            if(this.myDataOnly && this.myGudang)  params.gudang_id = this.myGudang.id
            if(this.isGM) params.jenis = 2

            this.returs = await this.$store.dispatch('retur-supplier/getData', params)
            this.totalRows = this.returs.length
        },
        detail(item) {
            this.$router.push(`/retur-supplier/detail/${item.id}`)
        },
        setField() {
            if(this.hasPermissionApprove && (this.isOwner || this.isGM)) {
                this.fields = [
                    {key: 'order', label: 'No'},
                    {key: 'jenis', label: 'Jenis Retur Barang'},
                    {key: 'supplier', label: 'Supplier'},
                    {key: 'tanggal', label: 'Tanggal'},
                    {key: 'status', label: 'Status'},
                    {key: 'actions', label: '#'}
                ]
            }
            else {
                this.fields = [
                    {key: 'order', label: 'No'},
                    {key: 'jenis', label: 'Jenis Pembelian Barang'},
                    {key: 'tanggal', label: 'Tanggal'},
                    {key: 'status', label: 'Status'}, // sudah atau belum
                    {key: 'actions', label: '#'}
                ]
            }
        },
        async checkPermissionApprove() {
            const menu = await this.currentMenu(this.$route.path)
            const params = {
                level_id: this.user.level.id
            }
            if(menu) {
                params.menu_id = menu.id
            }
            const listStatus = await this.$store.dispatch('statusrole/getData', params)

            // data_status => 1 approve gm, data_status => 2 pay finance
            const hasPermissionApprove = listStatus.some(status => status.data_status == 1)
            this.hasPermissionApprove = hasPermissionApprove || (this.isOwner || this.isGM)
        },
        async checkMyDataOnly() {
            const currentMenu = await this.currentMenu()
            const params = {
                level_id: this.user.level.id
            }

            if(currentMenu) params.menu_id = currentMenu.id

            const roles = await this.$store.dispatch('role/getData', params)
            if(roles.some(role => role.filter == true)) {
                this.myDataOnly = true
            }
        }
    },
    async mounted() {
        this.loading = true
        await this.checkMyDataOnly()
        await this.checkPermissionApprove()
        await this.setField()
        await this.getReturSupplier()
        this.loading = false
    }
}
</script>